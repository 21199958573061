import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { UserService } from './services/user/user.service';
import { AuthService } from './services/auth/auth.service';
import { Address } from './models/address.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  public selectedIndex = 0;
  public isLoged: boolean;
  public selectedPath: string;
  public pages = [
    { title: 'Menú', url: '/home', icon: 'grid-outline', color: '#607d8b' },
    { title: 'Preescolar', url: '/c;id=preescolar', icon: 'color-palette-outline', color: '#fc27ff' },
    { title: 'Primaria', url: '/c;id=primaria', icon: 'shapes-outline', color: '#00bcd4' },
    { title: 'Secundaria', url: '/c;id=secundaria', icon: 'flask-outline', color: '#8bc34a' },
    { title: 'Juegos', url: '/c;id=juegos', icon: 'game-controller-outline', color: '#673ab7' },
    { title: 'Pedido', url: '/order', icon: 'cart-outline', color: '#2196f3' },
    { title: 'Soporte', url: '/soporte', icon: 'logo-whatsapp', color: '#63c700' }
  ];

  public pagenone = [];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    public userService: UserService,
    public authService: AuthService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  dologout() {
    this.authService.logout();
    this.userService.lstAddress = new Array<Address>();
    this.userService.lstFavorites = [];
    this.router.navigate(['/home']);
  }

  inicio() {
    this.router.navigate(['/home']);
  }

  navigateto(url: string) {
    console.log(url);
    if (url.includes('preescolar')) {
      this.router.navigate(['/c', { id: 'preescolar' }]);
    } else if (url.includes('primaria')) {
      this.router.navigate(['/c', { id: 'primaria' }]);
    } else if (url.includes('secundaria')) {
      this.router.navigate(['/c', { id: 'secundaria' }]);
    } else if (url.includes('juegos')) {
      this.router.navigate(['/c', { id: 'juegos' }]);
    } else if (url.includes('ofertas')) {
      this.router.navigate(['/c', { id: 'ofertas' }]);
    } else if (url.includes('soporte')) {
      window.open("https://wa.me/5215568016775", '_blank');
    } else {
      this.router.navigate([url]);
    }
  }
}
